import styled from "styled-components"

const Title = styled.h1`
  font-size: 80px;
  margin: 0 0 40px 120px;
  line-height: 1.05;
  color: #1a1919;
  @media (max-width: 568px) {
    font-size: 36px;
    margin: 0 0 24px 65px;
  }
`
export default Title
