import React, { useState, useCallback, useEffect } from "react"
import styled from "styled-components"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Triangle from "../components/styles/Triangle"
import Title from "../components/styles/Title"
import Lightbox from "../components/styles/Lightbox"
import LightboxCloseBtn from "../components/styles/LightboxCloseBtn"
import CloseIcon from "../components/styles/CloseIcon"
import Img from "gatsby-image"
import arrow from "./../images/arrow-white.svg"
import { graphql, useStaticQuery } from "gatsby"

const Button = styled.button`
  cursor: pointer;
  padding: 0;
  border: 0;
  margin: 0;
  display: block;
  width: 100%;
  img {
    margin: 0;
    display: block;
  }
`

const Wrapper = styled.div`
  max-width: 1140px;
  position: relative;
  width: 100%;
`

const Frame = styled.div`
  max-height: 99vh;
  width: 100%;
`

const StyledImage = styled(Img)`
  max-height: 99vh;
  position: relative;
  &:after {
    content: "${props => (props.title ? `${props.title}` : ``)}";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    font-size: 24px;
    line-height: 30px;
    padding: 9px 10px;
    color: #fff;
    backdrop-filter: blur(10px);
    background-color: rgba(26, 25, 25, 0.4);
    font-weight: normal;
    text-align: center;
    @media (max-width: 568px) {
      font-size: 12px;
      padding: 5px;
      line-height: 14px;
    }
  }
`

const Navigation = styled.button`
  position: absolute;

  top: 50%;
  transform: translateY(-50%);

  left: 0;

  outline: none;
  border: none;

  padding: 15px;

  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.1);

  cursor: pointer;

  &:nth-of-type(2) {
    left: initial;
    right: 0;
  }

  @media (max-width: 568px) {
    padding: 10px;
  }
`

const ArrowLeft = styled.img`
  display: block;

  transform: rotate(180deg);

  margin: 0;

  width: 25px;

  @media (max-width: 568px) {
    width: 20px;
  }
`

const ArrowRight = styled.img`
  display: block;

  margin: 0;

  width: 25px;

  @media (max-width: 568px) {
    width: 20px;
  }
`

function GalleryPage() {
  const [imgIndex, setImgIndex] = useState(0)
  const [opened, setOpenLightbox] = useState(false)
  const [title, setLightboxTitle] = useState(null)
  const [imgSrc, setLightboxImg] = useState(null)
  const [orientation, setLightboxOrientation] = useState(null)

  const data = useStaticQuery(graphql`
    {
      allContentfulGallery {
        nodes {
          images {
            title
            fluid(maxWidth: 1500) {
              ...GatsbyContentfulFluid_withWebp
            }
            file {
              details {
                image {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  `)

  const images = data.allContentfulGallery.nodes[0].images

  const navigationHandler = newIndex => {
    let index
    if (newIndex < 0) {
      index = images.length - 1
    } else if (newIndex > images.length - 1) {
      index = 0
    } else {
      index = newIndex
    }

    let newImage = images[index]
    setLightboxTitle(newImage.title)
    setLightboxImg(newImage.fluid)
    setLightboxOrientation(getOrientation(newImage.file.details.image))
    setImgIndex(index)
  }

  const getOrientation = image => {
    return image.width >= image.height ? "landscape" : "portait"
  }

  const handleUserKeyPress = event => {
    const { key, keyCode } = event

    // if (keyCode == 91) {
    //     navigationHandler(imgIndex - 1)
    // }
    // if (keyCode == 39) {
    //     navigationHandler(imgIndex + 1)
    // }
    if (keyCode == 27) {
      setOpenLightbox(false)
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress)

    return () => {
      window.removeEventListener("keydown", handleUserKeyPress)
    }
  }, [])

  useEffect(() => {
    if (opened) {
      document.body.style.top = `-${window.scrollY}px`
      document.body.style.position = "fixed"
    } else {
      const scrollY = document.body.style.top
      document.body.style.position = ""
      document.body.style.top = ""
      window.scrollTo(0, parseInt(scrollY || "0") * -1)
    }
  }, [opened])

  return (
    <Layout>
      <Seo
        title="Gallery | komm schon Alter"
        description="This is how a mature dance floor vibe looks."
        keywords="Experience, Das Festival, club night, Amsterdam, house, techno, volwassen, tickets, line-up"
      />
      <div style={{ maxWidth: "960px", margin: "0 auto" }}>
        <Title>Gallery</Title>
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 600: 2, 900: 3 }}>
          <Masonry gutter={30}>
            {images.map((node, i) => {
              if (node.fluid) {
                return (
                  <Button
                    key={i}
                    onClick={() => {
                      setOpenLightbox(true)
                      setImgIndex(i)
                      setLightboxTitle(node.title)
                      setLightboxImg(node.fluid)
                      setLightboxOrientation(
                        getOrientation(node.file.details.image)
                      )
                    }}
                    onKeyDown={() => {
                      setOpenLightbox(true)
                      setImgIndex(i)
                      setLightboxTitle(node.title)
                      setLightboxImg(node.fluid)
                      setLightboxOrientation(
                        getOrientation(node.file.details.image)
                      )
                    }}
                  >
                    <Img fluid={node.fluid} alt={node.title} />
                  </Button>
                )
              }
            })}
          </Masonry>
        </ResponsiveMasonry>
      </div>
      <Triangle color="#ff9aaa"></Triangle>
      {opened && (
        <Lightbox bgcolor="rgba(26, 25, 25, 0.6)">
          <Wrapper
            style={{ maxWidth: orientation == "portait" ? "650px" : "1410px" }}
          >
            <Frame>
              <StyledImage
                title={title}
                orientation={orientation}
                fluid={imgSrc}
                alt={title}
              ></StyledImage>
              <Navigation onClick={() => navigationHandler(imgIndex - 1)}>
                <ArrowLeft src={arrow} />
              </Navigation>
              <Navigation onClick={() => navigationHandler(imgIndex + 1)}>
                <ArrowRight src={arrow} />
              </Navigation>
              <LightboxCloseBtn
                onClick={() => {
                  setOpenLightbox(false)
                }}
                onKeyDown={() => {
                  setOpenLightbox(false)
                }}
              >
                <CloseIcon />
              </LightboxCloseBtn>
            </Frame>
          </Wrapper>
        </Lightbox>
      )}
    </Layout>
  )
}
export default GalleryPage
